// General
import React from "react";

// Icons
import ArrowUp from "../../assets/icons/ArrowUp/ArrowUp";
import Share from "../../assets/icons/Share/Share";
import EditStandard from "../../assets/icons/EditStandard/EditStandard";

const Example = (props) => {
	return (
		<React.Fragment>
			<div className="subHeader">
				<div className="container flexContainer aiCenter headerWrapper">
					<div className="dFlex fg1 aiCenter">
						<div className="globalBack">Back</div>
						<div
							className="projectImageSmall mlM cPointer"
							style={{
								backgroundImage: `url()`,
								backgroundSize: "cover",
								backgroundPosition: "center",
							}}
						/>
						<p className="bold cDG mlS">Deliveroo search</p>
					</div>
					<button className="secondary mrS">Save changes</button>
					<button className="primary">Share</button>
				</div>
			</div>
			<div className="appContainer container dFlex">
				<div className="bg-cols" />
				<div className="w50p ptL prM">xx</div>
				<div className="w50p ptL plM">yy</div>
			</div>
		</React.Fragment>
	);
};

export default Example;
