import React from "react";

// Router
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";

const SignOut = ({ firebase }) => (
	<Link to="/" onClick={firebase.doSignOut}>
		<div className="pXS">
			<p className="cDG pbS navLink">Sign out</p>
		</div>
	</Link>
);

export default withFirebase(SignOut);
