// General
import React, { useState, useEffect } from "react";

// Router
import { Link } from "react-router-dom";

// Cookies
import Cookies from "js-cookie";

// Authorisation
import { AuthUserContext, withAuthorisation } from "../../components/Session";

import welcome1 from "../../assets/images/welcome/welcome-1.jpg";
import welcome2 from "../../assets/images/welcome/welcome-2.jpg";
import welcome3 from "../../assets/images/welcome/welcome-3.jpg";
import welcome4 from "../../assets/images/welcome/welcome-4.jpg";

// Icons
import ArrowForward from "../../assets/icons/ArrowForward/ArrowForward";

const Welcome = () => {
	const [section, setSection] = useState(0);
	const activeAccountId = Cookies.get("accountId");
	const [inviteLinkCopied, setInviteLinkCopied] = useState(false);

	function copyInviteLink() {
		navigator.clipboard.writeText(
			"https://typeflow.app/sign-up/" + activeAccountId
		);
		setInviteLinkCopied(true);
		setTimeout(() => setInviteLinkCopied(false), 1000);
	}

	return (
		<div className="h100p">
			<div className="backgroundWhite" />
			<div className="container">
				<div className="ptXL">
					<div className="welcomeLegend">
						<div
							className="dot mrS cPointer"
							onClick={() => setSection(0)}
							style={{
								backgroundColor:
									section === 0 ? "#1c1c1c" : "#ddd",
							}}
						/>
						<div
							className="dot mrS mlXS cPointer"
							onClick={() => setSection(1)}
							style={{
								backgroundColor:
									section === 1 ? "#1c1c1c" : "#ddd",
							}}
						/>
						<div
							className="dot mrS mlXS cPointer"
							onClick={() => setSection(2)}
							style={{
								backgroundColor:
									section === 2 ? "#1c1c1c" : "#ddd",
							}}
						/>
						<div
							className="dot mrS mlXS cPointer"
							onClick={() => setSection(3)}
							style={{
								backgroundColor:
									section === 3 ? "#1c1c1c" : "#ddd",
							}}
						/>
					</div>
					{section === 0 ? (
						<div className="fiB mw650 ptL pbM c">
							<div className="taC c">
								<h3 className="mbS bold">
									Welcome to Typeflow
								</h3>
							</div>
							<p className="pbL taC c">
								A simple tool to help you capture & organise
								your user feedback.
							</p>
							<img
								src={welcome1}
								className="w100p pbL"
								style={{
									bottom: "0",
								}}
							/>
							<div className="c taC">
								<button disabled className="secondary mrS">
									Previous
								</button>
								<button
									className="primary"
									onClick={() => setSection(1)}
								>
									Next
								</button>
							</div>
						</div>
					) : null}
					{section === 1 ? (
						<div className="fiB mw650 ptL pbM c">
							<div className="taC c">
								<h3 className="mbS bold">
									Add, tag, and prioritise notes
								</h3>
							</div>
							<p className="pbL taC c">
								Collaboratively add notes as you observe user
								feedback sessions.
							</p>
							<img
								src={welcome2}
								className="w100p pbL"
								style={{
									bottom: "0",
								}}
							/>
							<div className="c taC">
								<button
									className="secondary mrS"
									onClick={() => setSection(0)}
								>
									Previous
								</button>
								<button
									className="primary"
									onClick={() => setSection(2)}
								>
									Next
								</button>
							</div>
						</div>
					) : null}
					{section === 2 ? (
						<div className="fiB mw650 ptL pbM c">
							<div className="taC c">
								<h3 className="mbS bold">
									Instantly generate reports
								</h3>
							</div>
							<p className="pbL taC c">
								Create a shareable report with one click.
							</p>
							<img
								src={welcome3}
								className="w100p pbL"
								style={{
									bottom: "0",
								}}
							/>
							<div className="c taC">
								<button
									className="secondary mrS"
									onClick={() => setSection(1)}
								>
									Previous
								</button>
								<button
									className="primary"
									onClick={() => setSection(3)}
								>
									Next
								</button>
							</div>
						</div>
					) : null}
					{section === 3 ? (
						<div className="fiB mw650 ptL pbM c">
							<div className="taC c">
								<h3 className="mbS bold">
									Invite your team members
								</h3>
							</div>
							<p className="pbL taC c">
								(You can also do this later in settings).
							</p>
							<div className="posRel dFlex aiCenter jcCenter">
								<img
									src={welcome4}
									className="w100p pbL"
									style={{
										bottom: "0",
									}}
								/>
								<div className="posAbs pM bgW br taC c test">
									<p className="small semibold pS bgG mbM">
										https://typeflow.app/sign-up/
										{activeAccountId}
									</p>
									{inviteLinkCopied ? (
										<button
											className="tertiary taC c"
											onClick={() => copyInviteLink()}
										>
											Copied
										</button>
									) : (
										<button
											className="tertiary taC c"
											onClick={() => copyInviteLink()}
										>
											Copy
										</button>
									)}
								</div>
							</div>
							<div className="c taC">
								<button
									className="secondary mrS"
									onClick={() => setSection(2)}
								>
									Previous
								</button>

								<Link to="/projects">
									<button className="primary">
										Get started
									</button>
								</Link>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

const condition = (authUser) => !!authUser;

export default withAuthorisation(condition)(Welcome);
