// React
import React, { useState } from "react";

// Router
import { Link } from "react-router-dom";

// Assets: Images
import landing1 from "../../../assets/images/landing/landing-1.jpg";

// Assets: Icons
import OpenInNew from "../../../assets/icons/OpenInNew/OpenInNew";
import ArrowForward from "../../../assets/icons/ArrowForward/ArrowForward";

const LandingHero = (props) => {
	const [emailValue, setEmailValue] = useState("");
	return (
		<div className="container fiB">
			<div className="landing-hero">
				<h1 className="landing-hero-headline">
					Capture &amp; organise your{" "}
					<span style={{ color: "var(--blue)" }}>user feedback</span>
				</h1>
				<p className="landing-hero-description">
					Add, tag, and prioritise the notes you take during user
					testing sessions and user interviews. Export them to a
					shareable report with one click.
				</p>
				<form
					action="https://samstuartcook.us19.list-manage.com/subscribe/post"
					method="POST"
					className="landing-signup"
				>
					<input
						type="hidden"
						name="u"
						value="8b2ac6cbcc833b68b31df6e9d"
					/>
					<input type="hidden" name="id" value="d4342bc21d" />

					<input
						type="email"
						placeholder="Email address"
						className="landing-signup-input"
						name="EMAIL"
						value={emailValue}
						onChange={(e) => {
							setEmailValue(e.target.value);
						}}
						autoCapitalize="off"
						autoCorrect="off"
					/>

					<button
						type="submit"
						value="Subscribe"
						name="subscribe"
						id="mc-embedded-subscribe"
						className="landing-signup-cta primary"
					>
						Get early access
					</button>
				</form>
				{/*
				<div className="landing-hero-actions">
					<Link to="#">
						<button className="primary landing-hero-actions-primary">
							<div className="flexContainer">
								<ArrowForward fill="#fff" size="16" />
								Get started
							</div>
						</button>
					</Link>
					<a href="#" target="_blank" rel="noopener noreferrer">
						<button className="secondary landing-hero-actions-secondary">
							<div className="flexContainer">
								<OpenInNew fill="#1c1c1c" size="16" />
								Try a live demo
							</div>
						</button>
					</a>
				</div>*/}
				<div className="ptXL">
					<div className="imageContainerCentre">
						<img src={landing1} className="landing-image" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingHero;
