// General
import React, { useState, useEffect } from "react";

// Firebase
import firebase from "../../components/Firebase";

// Router
import { Link } from "react-router-dom";

// Components
import CardIssue from "../../components/CardIssue/CardIssue";
import Label from "../../components/Label/Label";
import DataVisualisation from "../../components/DataVisualisation/DataVisualisation";

// Icons
import ArrowUp from "../../assets/icons/ArrowUp/ArrowUp";

const SORT_OPTIONS = {
	NEWEST: { column: "created", direction: "desc" },
	TITLE_ASC: { column: "issueIndexable", direction: "asc" },
	TITLE_DESC: { column: "issueIndexable", direction: "desc" },
	UPVOTES: { column: "upvotes", direction: "desc" },
};

function useDefaultName(activeProjectId) {
	const [defaultName, setDefaultName] = useState("");

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("projects")
			.doc(activeProjectId)
			.get()
			.then((doc) => {
				const data = doc.data();
				setDefaultName(data.name);
			});
	}, []);

	return defaultName;
}

function useDefaultImageURL(activeProjectId) {
	const [defaultImageURL, setDefaultImageURL] = useState("");

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("projects")
			.doc(activeProjectId)
			.get()
			.then((doc) => {
				const data = doc.data();
				setDefaultImageURL(data.imageURL);
			});
	}, []);

	return defaultImageURL;
}

function useIssues(sortBy = "UPVOTES", activeProjectId) {
	const [issues, setIssues] = useState([]);

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("issues")
			.where("projectId", "==", activeProjectId)
			.orderBy(
				SORT_OPTIONS[sortBy].column,
				SORT_OPTIONS[sortBy].direction
			)
			.onSnapshot((snapshot) => {
				const newIssues = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setIssues(newIssues);
			});
		return () => unsubscribe();
	}, [sortBy]);

	return issues;
}

const Share = ({ match }) => {
	const [sortBy, setSortBy] = useState("UPVOTES");
	const activeProjectId = match.params.id;
	const [name, setName] = useState("");
	const [imageURL, setImageURL] = useState("");
	const defaultName = useDefaultName(activeProjectId);
	const defaultImageURL = useDefaultImageURL(activeProjectId);
	const issues = useIssues(sortBy, activeProjectId);

	// STATS BLOCK
	const allUpvotes = issues.map((issue) => issue.upvotes);
	const totalUpvotes = allUpvotes.reduce(function(a, b) {
		return a + b;
	}, 0);
	const allIssueCategories = issues.map((issue) =>
		issue.categories.toString()
	);
	const allIssueCategoriesList = allIssueCategories.filter(
		(category) => category.length > 0
	);
	const totalCategories = [...new Set(allIssueCategoriesList)];
	const allIssueTags = issues.map((issue) => issue.tags.toString());
	const allIssueTagsList = allIssueTags.filter((tag) => tag.length > 0);
	const totalTags = [...new Set(allIssueTagsList)];
	const issuesWithTags = issues.filter((issue) => issue.tags.length > 0);
	const issuesWithCategories = issues.filter(
		(issue) => issue.categories.length > 0
	);
	const issuesWithUpvotes = issues.filter((issue) => issue.upvotes > 0);

	// Charts
	const [activeChart, setActiveChart] = useState("Radar");

	// Filters
	const [categoryFilters, setCategoryFilters] = useState([]);
	const [tagFilters, setTagFilters] = useState([]);
	const [upvoteFilters, setUpvoteFilters] = useState([]);
	const [filters, setFilters] = useState({
		category: null,
		tag: null,
		hasVotes: null,
	});

	const filteredIssues = useFilteredIssues(issues, filters);

	// UI
	const [shareLinkModal, setShareLinkModal] = useState(false);
	const [shareLinkCopied, setShareLinkCopied] = useState(false);

	function useFilteredIssues(issues, filters) {
		let issuesFilteredByCategory = issues;
		let issuesFilteredByTag = issues;
		let issuesFilteredByHasVotes = issues;
		let filteredIssues = issues;

		if (filters.category === null) {
			issuesFilteredByCategory = issues;
		} else {
			issuesFilteredByCategory = issues.filter((issue) =>
				issue.categories.includes(filters.category)
			);
		}

		if (filters.tag === null) {
			issuesFilteredByTag = issuesFilteredByCategory;
		} else {
			issuesFilteredByTag = issuesFilteredByCategory.filter((issue) =>
				issue.tags.includes(filters.tag)
			);
		}

		if (filters.hasVotes === null) {
			issuesFilteredByHasVotes = issuesFilteredByTag;
		} else {
			issuesFilteredByHasVotes = issuesFilteredByTag.filter(
				(issue) => issue.upvotes > 0
			);
		}

		return issuesFilteredByHasVotes;
	}

	// UI
	const [filtersModal, setFiltersModal] = useState(false);

	useEffect(() => {
		if (name === "") {
			setName(defaultName);
		}
	}, [defaultName]);

	useEffect(() => {
		if (imageURL === "") {
			setImageURL(defaultImageURL);
		}
	}, [defaultImageURL]);

	function resetFilters() {
		setFilters({ category: null, tag: null, hasVotes: null });
	}

	function copyShareLink() {
		navigator.clipboard.writeText(
			"http://typeflow.app/projects/share/" + activeProjectId
		);
		setShareLinkCopied(true);
		setTimeout(() => setShareLinkCopied(false), 1000);
	}

	return (
		<div>
			{filtersModal ? (
				<div className="backdrop">
					<div className="modal pM br">
						<p className="large cDG semibold mbM">Apply filters</p>
						<p className="semibold small mbXS">
							Filter by category
						</p>
						{totalCategories.length > 0 ? (
							<div>
								{totalCategories.map((category) => (
									<div
										className="plS prS ptXS pbXS mrXS mbXS br dIB cPointer hoverBackground"
										style={{
											border:
												filters.category === category
													? "1px solid #1c1c1c"
													: "1px solid #dfdfe1",
										}}
										onClick={() =>
											filters.category === category
												? setFilters({
														category: null,
														tag: filters.tag,
														hasVotes:
															filters.hasVotes,
												  })
												: setFilters({
														category: category,
														tag: filters.tag,
														hasVotes:
															filters.hasVotes,
												  })
										}
									>
										<p
											className="small"
											style={{
												opacity:
													filters.category ===
													category
														? "1"
														: ".6",
											}}
										>
											{category}
										</p>
									</div>
								))}
							</div>
						) : (
							<div className="pS bgG br">
								<p className="small o6">
									No categories to show
								</p>
							</div>
						)}

						<p className="semibold small mtM mbXS">Filter by tag</p>
						{totalTags.length > 0 ? (
							<div>
								{totalTags.map((tag) => (
									<div
										className="plS prS ptXS pbXS mrXS mbXS br dIB cPointer hoverBackground"
										style={{
											border:
												filters.tag === tag
													? "1px solid #1c1c1c"
													: "1px solid #dfdfe1",
										}}
										onClick={() =>
											filters.tag === tag
												? setFilters({
														category:
															filters.category,
														tag: null,
														hasVotes:
															filters.hasVotes,
												  })
												: setFilters({
														category:
															filters.category,
														tag: tag,
														hasVotes:
															filters.hasVotes,
												  })
										}
									>
										<p
											className="small"
											style={{
												opacity:
													filters.tag === tag
														? "1"
														: ".6",
											}}
										>
											{tag}
										</p>
									</div>
								))}
							</div>
						) : (
							<div className="pS bgG br">
								<p className="small o6">No tags to show</p>
							</div>
						)}

						<p className="semibold small mtM mbXS">
							Filter by upvotes
						</p>
						{issuesWithUpvotes.length > 0 ? (
							<div
								className="plS prS ptXS pbXS mrXS mbXS br dIB cPointer hoverBackground"
								style={{
									border:
										filters.hasVotes === true
											? "1px solid #1c1c1c"
											: "1px solid #dfdfe1",
								}}
								onClick={() =>
									filters.hasVotes === true
										? setFilters({
												category: filters.category,
												tag: filters.tag,
												hasVotes: null,
										  })
										: setFilters({
												category: filters.category,
												tag: filters.tag,
												hasVotes: true,
										  })
								}
							>
								<p
									className="small"
									style={{
										opacity:
											filters.hasVotes === true
												? "1"
												: ".6",
									}}
								>
									Issues with upvotes
								</p>
							</div>
						) : (
							<div className="pS bgG br">
								<p className="small o6">
									No issues with upvotes
								</p>
							</div>
						)}

						<div className="dFlex mtM">
							<p
								className="pS small semibold cPointer hoverBackground"
								onClick={resetFilters}
							>
								Reset filters
							</p>
							<div className="fg1" />
							<button
								className="mlS primary"
								onClick={() => setFiltersModal(false)}
							>
								See {filteredIssues.length} issues
							</button>
						</div>
					</div>
				</div>
			) : null}
			{shareLinkModal ? (
				<div className="backdrop">
					<div className="modal pM br">
						<p className="large cDG semibold mbM">Share via link</p>
						<div className="dFlex aiCenter pM bgG">
							<p className="semibold small mbXS fg1">
								{"http://typeflow.app/projects/share/" +
									activeProjectId}
							</p>
							{shareLinkCopied ? (
								<button
									className="tertiary"
									onClick={copyShareLink}
								>
									Copied
								</button>
							) : (
								<button
									className="tertiary"
									onClick={copyShareLink}
								>
									Copy
								</button>
							)}
						</div>

						<div className="dFlex mtM">
							<div className="fg1" />
							<button
								className="mlS primary"
								onClick={() => setShareLinkModal(false)}
							>
								Done
							</button>
						</div>
					</div>
				</div>
			) : null}
			<div className="ptXL bgW">
				<div className="borderB">
					<div className="container">
						<div className="pbXL">
							<div className="dFlex aiFlexEnd">
								<div className="w70p c">
									<div
										className="c projectImage"
										style={{
											backgroundImage: `url(${imageURL})`,
											backgroundSize: "cover",
											backgroundPosition: "center",
										}}
									/>
									<h2 className="mtM semibold taC">{name}</h2>
									<div className="dFlex jcCenter mtM">
										<Link
											to={
												"/projects/print/" +
												activeProjectId
											}
											target="_blank"
										>
											<button className="secondary">
												Download PDF
											</button>
										</Link>
										<button
											className="primary mlS"
											onClick={() =>
												setShareLinkModal(true)
											}
										>
											Share via link
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bgG ptM">
					<div className="container">
						<div className="c" style={{ maxWidth: "800px" }}>
							<div className="test mbS">
								<div className="pS">
									<div className="pS br dFlex aiCenter">
										<p className="cDG semibold small">
											Overview
										</p>
									</div>
								</div>

								<div className="plM prM pbM">
									<div className="dFlex ptM pbM aiFlexStart">
										{issues.length > 0 ? (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{issues.length}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Notes</p>
											</div>
										) : (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Notes</p>
											</div>
										)}
										{totalUpvotes > 0 ? (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{totalUpvotes}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Upvotes</p>
											</div>
										) : (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Upvotes</p>
											</div>
										)}
										{totalCategories.length > 0 ? (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{totalCategories.length}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Categories</p>
											</div>
										) : (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Categories</p>
											</div>
										)}
										{totalTags.length > 0 ? (
											<div className="br plM prM taC w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{totalTags.length}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Tags</p>
											</div>
										) : (
											<div className="br plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Tags</p>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="test mbS">
								<div className="pS dFlex aiCenter">
									<div className="pS br dFlex aiCenter fg1">
										<p className="cDG semibold small">
											Charts
										</p>
									</div>
									<div
										className="pS br cPointer hoverOpacity mlS"
										onClick={() => setActiveChart("Bar")}
										style={{
											background:
												activeChart === "Bar"
													? "#f6f9fc"
													: "transparent",
										}}
									>
										<p className="cDG normal small">Bar</p>
									</div>
									<div
										className="pS br cPointer hoverOpacity mlS"
										onClick={() => setActiveChart("Radar")}
										style={{
											background:
												activeChart === "Radar"
													? "#f6f9fc"
													: "transparent",
										}}
									>
										<p className="cDG normal small">
											Radar
										</p>
									</div>
								</div>

								<div className="plM prM pbM">
									{issuesWithCategories.length > 0 ||
									issuesWithTags.length > 0 ? (
										<DataVisualisation
											issues={issues}
											activeChart={activeChart}
										/>
									) : (
										<div className="w100p">
											<div className="border br pM taC c w100p">
												<div className="o2">
													<h3 className="semibold mtL mbL">
														No data to show.
													</h3>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="test mbS">
								<div className="pS">
									<div className="pS br dFlex aiCenter">
										<p className="cDG semibold small fg1">
											Notes
										</p>
										<button
											className="secondary mrS"
											onClick={() =>
												setFiltersModal(true)
											}
										>
											Apply filters
										</button>
										<select
											value={sortBy}
											onChange={(e) =>
												setSortBy(e.currentTarget.value)
											}
										>
											<option value="UPVOTES">
												Most upvoted
											</option>
											<option value="NEWEST">
												Newest
											</option>
											<option value="TITLE_ASC">
												A&ensp;&ndash;&ensp;Z
											</option>

											<option value="TITLE_DESC">
												Z&ensp;&ndash;&ensp;A
											</option>
										</select>
									</div>
								</div>

								<div className="plM prM pbM">
									{filteredIssues.length > 0 ? (
										<div>
											{filteredIssues.map((issue) => (
												<CardIssue
													key={issue.id}
													issueId={issue.id}
													allIssues={issues}
													issue={issue.issue}
													categories={
														issue.categories
													}
													tags={issue.tags}
													upvotes={issue.upvotes}
													preview={true}
												/>
											))}
										</div>
									) : (
										<div className="w100p">
											<div className="border br pM taC c w100p">
												<div className="o2">
													<h3 className="semibold mtL mbL">
														No data to show.
													</h3>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Share;
