// React
import React, { useState } from "react";

// Components
import Label from "../../components/Label/Label";

// Icons
import Upvote from "../../assets/icons/Upvote/Upvote";

const CardIssue = (props) => {
	const [hover, setHover] = useState(false);
	return (
		<div
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<div className="border br bgW pS mbS">
				<div className="dFlex aiCenter">
					<div className="fg1 pS">
						<p className="cDG">{props.issue}</p>
						<div className="mtS">
							{props.categories && props.categories.length > 0 ? (
								<div className="dIB">
									{props.categories.map((category) => (
										<div className="dIB mbXS">
											<Label
												content="categories"
												value={category}
												allIssues={props.allIssues}
											/>
										</div>
									))}
								</div>
							) : null}

							{props.tags && props.tags.length > 0 ? (
								<div className="dIB">
									{props.tags.map((tag) => (
										<div className="dIB mbXS">
											<Label
												content="tags"
												value={tag}
												allIssues={props.allIssues}
											/>
										</div>
									))}
								</div>
							) : null}
						</div>
					</div>

					<div className="dFlex">
						{props.preview === true ? null : (
							<div
								className="mlS br border pM hoverBackground cPointer taC aiCenter dFlex"
								style={{
									visibility: hover ? "visible" : "hidden",
								}}
								onClick={() => props.delete(props.issueId)}
							>
								<p className="small semibold">Delete</p>
							</div>
						)}
						{props.preview === true ? (
							<div className="mlS br border pM taC">
								<Upvote fill="#1c1c1c" size="10px" />
								<p className="small bold">{props.upvotes}</p>
							</div>
						) : (
							<div
								className="mlS br border pM hoverBackground cPointer taC"
								onClick={() =>
									props.upvote(props.issueId, props.upvotes)
								}
							>
								<Upvote fill="#1c1c1c" size="10px" />
								<p className="small bold">{props.upvotes}</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardIssue;
