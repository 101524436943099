import React from "react";

const Label = (props) => {
	const tagQuantity = props.allIssues.filter((issue) =>
		issue.tags.includes(props.value)
	);

	const categoryQuantity = props.allIssues.filter((issue) =>
		issue.categories.includes(props.value)
	);

	const importantQuantity = props.allIssues.filter(
		(issue) => issue.important === true
	);

	return (
		<div className="dIB">
			<div
				className="label mrS"
				style={{
					backgroundColor:
						props.content === "tags"
							? "rgb(68, 200, 129)"
							: props.content === "categories"
							? "var(--teal)"
							: props.content === "important"
							? "#f84f77"
							: null,
				}}
			>
				{props.value}
				{props.content === "tags" ? (
					<span className="o6">
						&nbsp;&nbsp;&nbsp;{tagQuantity.length}
					</span>
				) : props.content === "categories" ? (
					<span className="o6">
						&nbsp;&nbsp;&nbsp;{categoryQuantity.length}
					</span>
				) : props.content === "important" ? (
					<span className="o6">
						&nbsp;&nbsp;&nbsp;{importantQuantity.length}
					</span>
				) : null}
			</div>
		</div>
	);
};

export default Label;
