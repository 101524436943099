// General
import React, { useState } from "react";

// Authorisation
import { AuthUserContext, withAuthorisation } from "../../components/Session";

// Router
import { Link } from "react-router-dom";

// Cookies
import Cookies from "js-cookie";

// Components
import Footer from "../../components/UI/Footer/Footer";

const Settings = () => {
	const activeAccountId = Cookies.get("accountId");
	const [inviteLinkCopied, setInviteLinkCopied] = useState(false);

	function copyInviteLink() {
		navigator.clipboard.writeText(
			"https://typeflow.app/sign-up/" + activeAccountId
		);
		setInviteLinkCopied(true);
		setTimeout(() => setInviteLinkCopied(false), 1000);
	}
	return (
		<React.Fragment>
			<div className="backgroundWhite" />
			<div className="ptXL bgW borderB">
				<div className="container">
					<div className="pbXL">
						<div className="dFlex aiFlexEnd">
							<div>
								<h2 className="semibold">Settings</h2>
								<p className="mtS">
									View, edit, and create projects
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bgW pbXXXL">
				<div className="container">
					<div className="fiB ptL dFlex aiFlexStart">
						<div className="w33p prL">
							<p className="cDG bold mbM cPointer">
								Team settings
							</p>
						</div>
						<div className="fg1">
							<div className="border br mbM">
								<div className="pM borderB">
									<h4 className="mbS">Invite team members</h4>
									<p className="mbS">Your invite link is:</p>
									<p className="pM bgG small">
										https://typeflow.app/sign-up/
										{activeAccountId}
									</p>
								</div>
								<div className="pM bgG br dFlex aiCenter">
									<p className="fg1"></p>
									{inviteLinkCopied ? (
										<button
											className="secondary"
											onClick={() => copyInviteLink()}
										>
											Copied!
										</button>
									) : (
										<button
											className="secondary"
											onClick={() => copyInviteLink()}
										>
											Copy to clipboard
										</button>
									)}
								</div>
							</div>
							{/*<div className="border br">
								<div className="pM borderB">
									<h4 className="mbS">Your username</h4>
									<p className="mbS">
										Please enter your full name, or a
										display name you are comfortable with.
									</p>
									<input type="text w100p" />
								</div>
								<div className="pM bgG br dFlex aiCenter">
									<p className="fg1">
										Only use an active email
									</p>
									<button className="primary">
										Save changes
									</button>
								</div>
							</div>*/}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const condition = (authUser) => !!authUser;

export default withAuthorisation(condition)(Settings);
