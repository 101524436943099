// General
import React, { Component } from "react";

// Firebase
import firebase from "../../components/Firebase";

// Cookies
import Cookies from "js-cookie";

// Router
import { Link, withRouter } from "react-router-dom";

// Recompose
import { compose } from "recompose";

// Firebase
import { withFirebase } from "../../components/Firebase";

// Constants
import * as ROUTES from "../../constants/routes";

// Icons
import CircleLogo from "../../assets/icons/CircleLogo/CircleLogo";

const SignIn = () => (
  <div>
    <div className="bgG">
      <div className="container">
        <div className="ptXL fiB">
          <div className="taC c mw650 pbM">
            <CircleLogo size="70px" />
            <h3 className="mtM mbS bold">Sign in to your account</h3>
            <p className="mbS">
              Or{" "}
              <Link to="/sign-up">
                <span className="semibold">create an account</span>
              </Link>
            </p>
          </div>
        </div>
        <SignInForm />
      </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        const activeUserId = authUser.user.uid;
        Cookies.set("activeUserId", activeUserId);
        firebase
          .firestore()
          .collection("users")
          .doc(activeUserId)
          .get()
          .then((doc) => {
            const data = doc.data();
            Cookies.set("accountId", data.accountId);
          });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push("/projects");
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <div className="mw470 c br ptM pbL plL prL fiB test">
        <form onSubmit={this.onSubmit}>
          <p className="mtS mbXS cDG semibold">Email address</p>
          <input
            className="w100p mbS"
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
          />
          <p className="mtS mbXS cDG semibold">Password</p>
          <input
            className="w100p mbM"
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
          />
          <Link to={ROUTES.FORGOT_PASSWORD}>
            <p className="small cB semibold mbM taR">Forgot your password?</p>
          </Link>
          <button className="primary w100p" disabled={isInvalid} type="submit">
            Sign In
          </button>

          {error && <p className="error small semibold mtM">{error.message}</p>}
        </form>
      </div>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignIn;

export { SignInForm };
