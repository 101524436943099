import * as actionTypes from "../actions";

const initialState = {
	activeUserId: "None",
	activeUserUsername: "None",
	activeUserEmail: "None",
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ACTIVE_USER: {
			return {
				...state,
				activeUserId: action.activeUserId,
				activeUserUsername: action.activeUserUsername,
				activeUserEmail: action.activeUserEmail,
			};
		}
	}
	return state;
};

export default reducer;
