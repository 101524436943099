// Marketing
export const LANDING = "/";
export const PRICING = "/pricing";
export const SIGN_UP = "/sign-up";
export const SIGN_IN = "/sign-in";
export const TERMS = "/terms";
export const EXAMPLE = "/example";
export const FORGOT_PASSWORD = "/forgot-password";
export const PRIVACY = "/privacy";

// App
export const WELCOME = "/welcome";
export const PROJECTS = "/projects";
