// General
import React, { useState } from "react";

const Stripe = () => {
	const [email, setEmail] = useState("");
	const [customer, setCustomer] = useState(null);

	const handleSubmit = (evt) => {
		evt.preventDefault();
		return fetch("/create-customer", {
			method: "post",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: email,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				setCustomer(result.customer);
			});

		const stripe = require("stripe")(
			"sk_test_M3cd67ohmsJfnPqn8Gk0lamP00tWdzwlre"
		);
	};

	return (
		<React.Fragment>
			<div className="backgroundWhite" />
			<div className="ptXL bgW borderB">
				<div className="container">
					<div className="pbXL">
						<div className="dFlex aiFlexEnd">
							<div>
								<h2 className="semibold">Cheeky Stripe Test</h2>
								<p className="mtS">
									Only for testing fdvfcdsxazfds
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bgG">
				<div className="container">
					<form id="signup-form" onSubmit={handleSubmit}>
						<div>
							<input
								id="email"
								type="text"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Email address"
								required
							/>
						</div>
						​
						<button
							id="email-submit"
							className="primary"
							type="submit"
						>
							<span id="button-text">Sign up</span>
						</button>
					</form>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Stripe;
