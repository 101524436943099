// General
import React, { useState, useEffect } from "react";

// Firebase
import firebase from "../../components/Firebase";

// Components
import CardIssue from "../../components/CardIssue/CardIssue";
import Label from "../../components/Label/Label";
import DataVisualisation from "../../components/DataVisualisation/DataVisualisation";

// Icons
import ArrowUp from "../../assets/icons/ArrowUp/ArrowUp";
import Logo from "../../assets/icons/Logo/Logo";

const SORT_OPTIONS = {
	NEWEST: { column: "created", direction: "desc" },
	TITLE_ASC: { column: "issueIndexable", direction: "asc" },
	TITLE_DESC: { column: "issueIndexable", direction: "desc" },
	UPVOTES: { column: "upvotes", direction: "desc" },
};

function useDefaultName(activeProjectId) {
	const [defaultName, setDefaultName] = useState("");

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("projects")
			.doc(activeProjectId)
			.get()
			.then((doc) => {
				const data = doc.data();
				setDefaultName(data.name);
			});
	}, []);

	return defaultName;
}

function useIssues(sortBy = "UPVOTES", activeProjectId) {
	const [issues, setIssues] = useState([]);

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("issues")
			.where("projectId", "==", activeProjectId)
			.orderBy(
				SORT_OPTIONS[sortBy].column,
				SORT_OPTIONS[sortBy].direction
			)
			.onSnapshot((snapshot) => {
				const newIssues = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setIssues(newIssues);
			});
		return () => unsubscribe();
	}, [sortBy]);

	return issues;
}

const Print = ({ match }) => {
	const [sortBy, setSortBy] = useState("UPVOTES");
	const activeProjectId = match.params.id;
	const [name, setName] = useState("");
	const [imageURL, setImageURL] = useState("");
	const defaultName = useDefaultName(activeProjectId);
	const issues = useIssues(sortBy, activeProjectId);

	// STATS BLOCK
	const allUpvotes = issues.map((issue) => issue.upvotes);
	const totalUpvotes = allUpvotes.reduce(function(a, b) {
		return a + b;
	}, 0);
	const allIssueCategories = issues.map((issue) =>
		issue.categories.toString()
	);
	const allIssueCategoriesList = allIssueCategories.filter(
		(category) => category.length > 0
	);
	const totalCategories = [...new Set(allIssueCategoriesList)];
	const allIssueTags = issues.map((issue) => issue.tags.toString());
	const allIssueTagsList = allIssueTags.filter((tag) => tag.length > 0);
	const totalTags = [...new Set(allIssueTagsList)];
	const issuesWithTags = issues.filter((issue) => issue.tags.length > 0);
	const issuesWithCategories = issues.filter(
		(issue) => issue.categories.length > 0
	);
	const issuesWithUpvotes = issues.filter((issue) => issue.upvotes > 0);

	// Charts
	const [activeChart, setActiveChart] = useState("Radar");

	// Filters
	const [categoryFilters, setCategoryFilters] = useState([]);
	const [tagFilters, setTagFilters] = useState([]);
	const [upvoteFilters, setUpvoteFilters] = useState([]);
	const [filters, setFilters] = useState({
		category: null,
		tag: null,
		hasVotes: null,
	});

	// Print
	const [printed, setPrinted] = useState(false);

	useEffect(() => {
		if (name === "") {
			setName(defaultName);
		}
	}, [defaultName]);

	useEffect(() => {
		if (issues && printed === false) {
			setTimeout(() => window.print(), 2000);
			setPrinted(true);
		}
	}, [issues]);

	return (
		<div>
			<header
				className="dFlex aiCenter jcCenter"
				style={{ zIndex: "1999" }}
			>
				<Logo className="taC c" />
			</header>
			<div className="ptXL bgW">
				<div style={{ borderBottom: "1px solid #dfdfe1" }}>
					<div className="container">
						<div className="pbXL">
							<div className="dFlex aiFlexEnd">
								<div className="w70p c">
									<h2 className="mtM semibold taC">{name}</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bgW ptM">
					<div className="container">
						<div className="c" style={{ maxWidth: "800px" }}>
							<div
								className="bgW mbS br"
								style={{ border: "1px solid #dfdfe1" }}
							>
								<div className="pS">
									<div className="pS br dFlex aiCenter">
										<p className="cDG semibold small">
											Overview
										</p>
									</div>
								</div>

								<div className="plM prM pbM">
									<div className="dFlex ptM pbM aiFlexStart">
										{issues.length > 0 ? (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{issues.length}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Notes</p>
											</div>
										) : (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Notes</p>
											</div>
										)}
										{totalUpvotes > 0 ? (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{totalUpvotes}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Upvotes</p>
											</div>
										) : (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Upvotes</p>
											</div>
										)}
										{totalCategories.length > 0 ? (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{totalCategories.length}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Categories</p>
											</div>
										) : (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Categories</p>
											</div>
										)}
										{totalTags.length > 0 ? (
											<div className="br plM prM taC w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{totalTags.length}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Tags</p>
											</div>
										) : (
											<div className="br plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Tags</p>
											</div>
										)}
									</div>
								</div>
							</div>
							<div
								className="mbS br"
								style={{ border: "1px solid #dfdfe1" }}
							>
								<div className="pS dFlex aiCenter">
									<div className="pS br dFlex aiCenter fg1">
										<p className="cDG semibold small">
											Charts
										</p>
									</div>
								</div>

								<div className="plM prM pbM">
									{issuesWithCategories.length > 0 ||
									issuesWithTags.length > 0 ? (
										<DataVisualisation
											issues={issues}
											activeChart={activeChart}
										/>
									) : (
										<div className="w100p">
											<div className="border br pM taC c w100p">
												<div className="o2">
													<h3 className="semibold mtL mbL">
														No data to show.
													</h3>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							<div
								className="mbS pagebreak mtXXL br"
								style={{ border: "1px solid #dfdfe1" }}
							>
								<div className="pS">
									<div className="pS br dFlex aiCenter">
										<p className="cDG semibold small fg1">
											Notes
										</p>
									</div>
								</div>

								<div className="plM prM pbM">
									{issues.length > 0 ? (
										<div>
											{issues.map((issue) => (
												<CardIssue
													key={issue.id}
													issueId={issue.id}
													allIssues={issues}
													issue={issue.issue}
													categories={
														issue.categories
													}
													tags={issue.tags}
													upvotes={issue.upvotes}
													preview={true}
												/>
											))}
										</div>
									) : (
										<div className="w100p">
											<div className="border br pM taC c w100p">
												<div className="o2">
													<h3 className="semibold mtL mbL">
														No data to show.
													</h3>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Print;
