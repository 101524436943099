// General
import React, { useState, useEffect, Component } from "react";

// Constants
import * as ROUTES from "../../constants/routes";

// Cookies
import Cookies from "js-cookie";

// Firebase
import firebase from "../../components/Firebase";

// Context
import { AuthUserContext } from "../Session";

// Router
import { Link, NavLink } from "react-router-dom";

// Components
import SignOut from "../../components/SignOut/SignOut";

// Icons
import Logo from "../../assets/icons/Logo/Logo";

class Navigation extends Component {
	render() {
		return (
			<header>
				<div className="container flexContainer headerWrapper">
					<div className="navBrand">
						<AuthUserContext.Consumer>
							{(authUser) =>
								authUser ? (
									<NavLink to="/projects" exact>
										<Logo />
									</NavLink>
								) : (
									<NavLink to="/" exact>
										<Logo />
									</NavLink>
								)
							}
						</AuthUserContext.Consumer>
					</div>
					<div className="desktopNav">
						<AuthUserContext.Consumer>
							{(authUser) =>
								authUser ? (
									<NavigationAuth />
								) : (
									<NavigationNonAuth />
								)
							}
						</AuthUserContext.Consumer>
					</div>
				</div>
			</header>
		);
	}
}

class NavigationAuth extends Component {
	state = {
		userControlBar: false,
		feedback: false,
		isCopied: false,
	};

	userControlBarToggle = (event) => {
		const currentValue = this.state.userControlBar;

		this.setState({
			userControlBar: !currentValue,
		});
	};

	feedbackToggle = (event) => {
		const currentValue = this.state.feedback;

		this.setState({
			feedback: !currentValue,
		});
	};

	copyEmail = () => {
		navigator.clipboard.writeText("support@typeflow.app");
		this.setState({
			isCopied: true,
		});
		setTimeout(
			() =>
				this.setState({
					isCopied: false,
				}),
			1000
		);
	};

	render() {
		return (
			<AuthUserContext.Consumer>
				{(authUser) => (
					<div className="flexContainer headerWrapper">
						<div className="dFlex aiCenter">
							<button
								className="secondary"
								onClick={this.feedbackToggle}
							>
								Request a feature
							</button>

							{this.state.feedback ? (
								<div className="posRel">
									<div className="fiB popOver feedback">
										<div className="pM w100p">
											<p className="mbM small">
												We're always looking to improve,
												and would welcome thoughts,
												feedback, bug reports, and new
												ideas to improve Typeflow .
											</p>

											<p className="br pS bgG w100p semibold small">
												support@typeflow.app
											</p>
										</div>

										<hr />
										<div className="pM">
											<button
												className="secondary mbS w100p"
												onClick={this.copyEmail}
											>
												{this.state.isCopied
													? "Copied!"
													: "Copy email to clipboard"}
											</button>
											<a href="mailto:support@typeflow.app">
												<button className="primary w100p">
													Send email
												</button>
											</a>
										</div>
									</div>
								</div>
							) : null}

							<div
								className="mlL cPointer flexContainer"
								onClick={this.userControlBarToggle}
							>
								<p>My account</p>
							</div>
						</div>
						{this.state.userControlBar ? (
							<div className="posRel">
								<div className="popOver fiB">
									<div className="ptM pbM plM prXL">
										<p className="small">Signed in as</p>
										<p className="bold small">
											{authUser.email}
										</p>
									</div>

									<hr />
									<Link to="/settings">
										<div className="plXS prXS ptXS">
											<p className="cDG pbS navLink">
												Settings
											</p>
										</div>
									</Link>
									<a href="mailto:support@typeflow.app">
										<div className="plXS prXS pbXS">
											<p className="cDG pbS navLink">
												Support
											</p>
										</div>
									</a>
									<hr />
									<SignOut />
								</div>
							</div>
						) : null}
					</div>
				)}
			</AuthUserContext.Consumer>
		);
	}
}

const NavigationNonAuth = () => (
	<nav>
		{/*<ul>
			<li className="mrM">
				<NavLink to={ROUTES.SIGN_IN} exact className="navLink">
					Sign In
				</NavLink>
			</li>
			<li>
				<Link to={ROUTES.SIGN_UP} className="navActions">
					<button className="primary">Get started</button>
				</Link>
			</li>
		</ul>*/}
	</nav>
);

export default Navigation;
