// General
import React from "react";
import "./App.css";

// Router
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";

// Constants
import * as ROUTES from "../constants/routes";

// Context
import { withAuthentication } from "../components/Session";

// Pages
import Settings from "../pages/Settings/Settings";
import Stripe from "../pages/Stripe/Stripe";
import Playground from "../pages/Playground/Playground";
import Share from "../pages/Share/Share";
import Print from "../pages/Print/Print";
import Projects from "../pages/Projects/Projects";
import Landing from "../pages/Landing/Landing";
import Example from "../pages/Example/Example";
import SignIn from "../pages/SignIn/SignIn";
import SignUp from "../pages/SignUp/SignUp";
import Welcome from "../pages/Welcome/Welcome";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";

// Components
import Layout from "../layout/Layout";
import Navigation from "../components/Navigation/Navigation";

const app = () => (
	<Router>
		<div className="App window">
			<Navigation />
			<Layout>
				<Route path="/stripe" exact component={Stripe} />
				<Route path="/sign-up/:id" exact component={SignUp} />
				<Route path="/projects/share/:id" exact component={Share} />
				<Route path="/projects/print/:id" exact component={Print} />
				<Route path="/projects/:id" exact component={Playground} />
				<Route path="/settings" exact component={Settings} />
				<Route path={ROUTES.PROJECTS} exact component={Projects} />
				<Route path={ROUTES.SIGN_IN} exact component={SignIn} />
				<Route path={ROUTES.EXAMPLE} exact component={Example} />
				<Route path={ROUTES.SIGN_UP} exact component={SignUp} />
				<Route path={ROUTES.LANDING} exact component={Landing} />
				<Route path={ROUTES.WELCOME} exact component={Welcome} />
				<Route
					path={ROUTES.FORGOT_PASSWORD}
					exact
					component={ForgotPassword}
				/>
			</Layout>
		</div>
	</Router>
);

export default withAuthentication(app);
