// General
import React, { useState, useEffect } from "react";

// Cookies
import Cookies from "js-cookie";

// Firebase
import firebase from "../../components/Firebase";

// Authorisation
import { AuthUserContext, withAuthorisation } from "../../components/Session";

// Router
import { Link } from "react-router-dom";

// Components
import CardIssue from "../../components/CardIssue/CardIssue";
import Label from "../../components/Label/Label";
import DataVisualisation from "../../components/DataVisualisation/DataVisualisation";

// Icons
import ArrowUp from "../../assets/icons/ArrowUp/ArrowUp";
import ArrowBack from "../../assets/icons/ArrowBack/ArrowBack";
import More from "../../assets/icons/More/More";
import Close from "../../assets/icons/Close/Close";
import Dropdown from "../../assets/icons/Dropdown/Dropdown";
import Upvote from "../../assets/icons/Upvote/Upvote";

const SORT_OPTIONS = {
	NEWEST: { column: "created", direction: "desc" },
	IMPORTANCE: { column: "important", direction: "desc" },
	TITLE_ASC: { column: "issueIndexable", direction: "asc" },
	TITLE_DESC: { column: "issueIndexable", direction: "desc" },
};

function useDefaultName(activeProjectId) {
	const [defaultName, setDefaultName] = useState("");

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("projects")
			.doc(activeProjectId)
			.get()
			.then((doc) => {
				const data = doc.data();
				setDefaultName(data.name);
			});
	}, []);

	return defaultName;
}

function useDefaultImageURL(activeProjectId) {
	const [defaultImageURL, setDefaultImageURL] = useState("");

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("projects")
			.doc(activeProjectId)
			.get()
			.then((doc) => {
				const data = doc.data();
				setDefaultImageURL(data.imageURL);
			});
	}, []);

	return defaultImageURL;
}

function useDefaultTags(activeProjectId) {
	const [defaultTags, setDefaultTags] = useState([]);

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("projects")
			.doc(activeProjectId)
			.get()
			.then((doc) => {
				const data = doc.data();
				if (data.tags && data.tags.length > 0) {
					setDefaultTags(data.tags);
				}
			});
	}, []);

	return defaultTags;
}

function useDefaultCategories(activeProjectId) {
	const [defaultCategories, setDefaultCategories] = useState([]);

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("projects")
			.doc(activeProjectId)
			.get()
			.then((doc) => {
				const data = doc.data();
				if (data.categories && data.categories.length > 0) {
					setDefaultCategories(data.categories);
				}
			});
	}, []);

	return defaultCategories;
}

function useIssues(sortBy = "NEWEST", activeProjectId) {
	const [issues, setIssues] = useState([]);

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("issues")
			.where("projectId", "==", activeProjectId)
			.orderBy(
				SORT_OPTIONS[sortBy].column,
				SORT_OPTIONS[sortBy].direction
			)
			.onSnapshot((snapshot) => {
				const newIssues = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setIssues(newIssues);
			});
		return () => unsubscribe();
	}, [sortBy]);

	return issues;
}

const Playground = ({ match }) => {
	// GLOBAL
	const activeProjectId = match.params.id;
	const activeUserId = Cookies.get("activeUserId");
	const activeAccountId = Cookies.get("accountId");
	// UI
	const [activeSection, setActiveSection] = useState("Getting started");
	const [categoryInputFocus, setCategoryInputFocus] = useState(false);
	const [tagInputFocus, setTagInputFocus] = useState(false);
	const [saved, setSaved] = useState(false);
	const [sortBy, setSortBy] = useState("NEWEST");
	// DETAILS
	const defaultName = useDefaultName(activeProjectId);
	const defaultImageURL = useDefaultImageURL(activeProjectId);
	const defaultTags = useDefaultTags(activeProjectId);
	const defaultCategories = useDefaultCategories(activeProjectId);

	const [name, setName] = useState("");
	const [imageURL, setImageURL] = useState("");
	const [tags, setTags] = useState([]);
	const [categories, setCategories] = useState([]);

	const [issueCategories, setIssueCategories] = useState([]);
	const [newIssueCategory, setNewIssueCategory] = useState("");

	const issues = useIssues(sortBy, activeProjectId);
	const [issue, setIssue] = useState("");
	const [issueTags, setIssueTags] = useState([]);
	const [newIssueTag, setNewIssueTag] = useState("");
	const [issueImportantChecked, setIssueImportantChecked] = useState(false);

	// STATS BLOCK
	const allUpvotes = issues.map((issue) => issue.upvotes);
	const totalUpvotes = allUpvotes.reduce(function(a, b) {
		return a + b;
	}, 0);
	const allIssueCategories = issues.map((issue) =>
		issue.categories.toString()
	);
	const allIssueCategoriesList = allIssueCategories.filter(
		(category) => category.length > 0
	);
	const totalCategories = [...new Set(allIssueCategoriesList)];
	const allIssueTags = issues.map((issue) => issue.tags.toString());
	const allIssueTagsList = allIssueTags.filter((tag) => tag.length > 0);
	const totalTags = [...new Set(allIssueTagsList)];

	// UI
	const [image, setImage] = useState(false);
	const [shareLinkCopied, setShareLinkCopied] = useState(false);
	const [overviewExpanded, setOverviewExpanded] = useState(false);
	const [overviewHover, setOverviewHover] = useState(false);
	const [chartsExpanded, setChartsExpanded] = useState(false);
	const [chartsHover, setChartsHover] = useState(false);
	const [notesExpanded, setNotesExpanded] = useState(true);
	const [notesHover, setNotesHover] = useState(false);
	const issuesWithTags = issues.filter((issue) => issue.tags.length > 0);
	const issuesWithCategories = issues.filter(
		(issue) => issue.categories.length > 0
	);
	const [activeChart, setActiveChart] = useState("Radar");

	var dayjs = require("dayjs");
	var relativeTime = require("dayjs/plugin/relativeTime");
	dayjs.extend(relativeTime);

	useEffect(() => {
		if (name === "") {
			setName(defaultName);
		}
	}, [defaultName]);

	useEffect(() => {
		if (imageURL === "") {
			setImageURL(defaultImageURL);
		}
	}, [defaultImageURL]);

	useEffect(() => {
		if (tags.length < 1) {
			setTags(defaultTags);
		}
	}, [defaultTags]);

	useEffect(() => {
		if (categories.length < 1) {
			setCategories(defaultCategories);
		}
	}, [defaultCategories]);

	function updateDetails() {
		const now = dayjs().toString();

		firebase
			.firestore()
			.collection("projects")
			.doc(activeProjectId)
			.set(
				{
					name: name,
					nameIndexable: name.toLowerCase(),
					edited: now,
					imageURL: imageURL,
				},
				{ merge: true }
			);

		setSaved(true);

		setTimeout(() => setSaved(false), 1500);
	}

	function addIssueCategory(category) {
		setIssueCategories([...issueCategories, category]);
		setNewIssueCategory("");
		setCategoryInputFocus(false);
	}

	function deleteIssueCategory(deletedCategory) {
		const updatedCategories = issueCategories.filter(
			(category) => category !== deletedCategory
		);
		setIssueCategories(updatedCategories);

		return issueCategories;
	}

	function addIssueTag(tag) {
		setIssueTags([...issueTags, tag]);
		setNewIssueTag("");
		setTagInputFocus(false);
	}

	function deleteIssueTag(deletedTag) {
		const updatedTags = issueTags.filter((tag) => tag !== deletedTag);
		setIssueTags(updatedTags);

		return issueTags;
	}

	function handleCategoryInputFocus() {
		setCategoryInputFocus(true);
	}

	function handleCategoryInputBlur() {
		setCategoryInputFocus(false);
	}

	function handleTagInputFocus() {
		setTagInputFocus(true);
	}

	function handleTagInputBlur() {
		setTagInputFocus(false);
	}

	function toggleImage() {
		const currentValue = image;
		const newValue = !currentValue;
		setImage(newValue);
	}

	function addIssue() {
		const now = dayjs().toString();
		const newProjectCategories = categories.concat(
			issueCategories.filter((item) => categories.indexOf(item) < 0)
		);
		const newProjectTags = tags.concat(
			issueTags.filter((item) => tags.indexOf(item) < 0)
		);

		firebase
			.firestore()
			.collection("issues")
			.add({
				created: now,
				userId: activeUserId,
				accountId: activeAccountId,
				projectId: activeProjectId,
				issue: issue,
				issueIndexable: issue.toLowerCase(),
				tags: issueTags,
				categories: issueCategories,
				upvotes: 0,
			});

		firebase
			.firestore()
			.collection("projects")
			.doc(activeProjectId)
			.set(
				{
					tags: newProjectTags,
					categories: newProjectCategories,
					edited: now,
				},
				{ merge: true }
			);

		setIssue("");
		setIssueTags([]);
		setIssueCategories([]);
		setCategories(newProjectCategories);
		setTags(newProjectTags);
		setIssueImportantChecked(false);
	}

	function deleteIssue(issueId) {
		firebase
			.firestore()
			.collection("issues")
			.doc(issueId)
			.delete();
	}

	function upvoteIssue(issueId, issueUpvotes) {
		const newUpvotes = issueUpvotes + 1;
		firebase
			.firestore()
			.collection("issues")
			.doc(issueId)
			.set(
				{
					upvotes: newUpvotes,
				},
				{ merge: true }
			);
	}

	// function copyShareLink() {
	// 	navigator.clipboard.writeText(
	// 		"http://typeflow.app/projects/share/" + activeProjectId
	// 	);
	// 	setShareLinkCopied(true);
	// 	setTimeout(() => setShareLinkCopied(false), 1000);
	// }

	return (
		<div className="bgG">
			<div className="subHeader">
				<div className="container flexContainer aiCenter headerWrapper">
					<div className="dFlex fg1 aiCenter">
						<Link to="/projects">
							<div className="globalBack dFlex aiCenter cPointer hoverOpacity">
								<ArrowBack size="21px" fill="#1c1c1c" />{" "}
								<p className="mlS mrM small bold">
									Back to projects
								</p>
							</div>
						</Link>
					</div>
				</div>
			</div>
			<div className="appContainer container fiB">
				<div className="mtM dFlex aiCenter">
					<div
						className="projectImage mrS cPointer"
						onClick={toggleImage}
						style={{
							backgroundImage: `url(${imageURL})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
						}}
					/>
					{image ? (
						<div className="posRel">
							<div className="fiB popOver image">
								<div className="pM">
									<p className="small bold mbS">Image URL</p>
									<input
										type="text"
										className="w100p"
										value={imageURL}
										onChange={(e) =>
											setImageURL(e.currentTarget.value)
										}
									/>
								</div>
							</div>
						</div>
					) : null}

					<input
						type="text"
						className="ghostInput fg1"
						placeholder="Add project name here"
						value={name}
						onChange={(e) => setName(e.currentTarget.value)}
					/>
					<div>
						{saved ? (
							<button
								className="mlM secondary mrS"
								onClick={updateDetails}
							>
								Saved!
							</button>
						) : (
							<button
								className="mlM secondary mrS"
								onClick={updateDetails}
							>
								Save changes
							</button>
						)}
						<Link
							to={"/projects/share/" + activeProjectId}
							target="_blank"
						>
							<button className="primary">Generate report</button>
						</Link>
					</div>
				</div>
				<div className="mtM dFlex aiFlexStart">
					<div
						className="w33p test pM mrS"
						style={{ minWidth: "33%" }}
					>
						<p className="cDG mbXS semibold small">Add note</p>
						<textarea
							type="text"
							className="w100p large"
							value={issue}
							onChange={(e) => setIssue(e.currentTarget.value)}
						/>

						<p className="cDG mtM mbXS semibold small">
							Category{" "}
							<span className="cDG normal small">
								(e.g. 'Accessibility', 'Bug', or 'UX')
							</span>
						</p>

						{issueCategories.length > 0 ? (
							<input
								disabled
								type="text"
								className="w100p"
								onFocus={handleCategoryInputFocus}
								onBlur={handleCategoryInputBlur}
								value={newIssueCategory}
								onChange={(e) =>
									setNewIssueCategory(e.currentTarget.value)
								}
							/>
						) : (
							<input
								type="text"
								className="w100p"
								onFocus={handleCategoryInputFocus}
								onBlur={handleCategoryInputBlur}
								value={newIssueCategory}
								onChange={(e) =>
									setNewIssueCategory(e.currentTarget.value)
								}
							/>
						)}

						<div className="w100p posRel">
							{issueCategories.map((category) => (
								<div
									className="label mrS cPointer inputList"
									style={{
										backgroundColor: "var(--teal",
									}}
									onClick={(e) =>
										deleteIssueCategory(category)
									}
								>
									<div className="dFlex aiCenter">
										<div className="h100p mrXS">
											{category}
										</div>

										<Close fill="#fff" size="16px" />
									</div>
								</div>
							))}

							{categoryInputFocus ? (
								<div>
									{newIssueCategory.length > 0 ||
									categories.length > 0 ? (
										<div className="autopredictContainer posAbs w100p mtXS border br bgW pbXS">
											{newIssueCategory.length > 0 ? (
												<div
													className="ptXS plXS prXS"
													onMouseDown={() =>
														addIssueCategory(
															newIssueCategory
														)
													}
												>
													<div className="pS autopredictItem br cPointer">
														<div className="dFlex aiCenter">
															<div
																className="dot mrS"
																style={{
																	backgroundColor:
																		"var(--teal)",
																}}
															/>
															<p className="cDG small">
																Add "
																<span className="semibold">
																	{
																		newIssueCategory
																	}
																</span>
																"
															</p>
														</div>
													</div>
												</div>
											) : null}
											{categories.map((category) => (
												<div
													className="ptXS plXS prXS"
													onMouseDown={() =>
														addIssueCategory(
															category
														)
													}
												>
													<div className="pS autopredictItem cPointer">
														<div className="dFlex aiCenter">
															<div
																className="dot mrS"
																style={{
																	backgroundColor:
																		"var(--teal)",
																}}
															/>
															<p className="cDG small">
																{category}
															</p>
														</div>
													</div>
												</div>
											))}
										</div>
									) : null}
								</div>
							) : null}
						</div>

						<p className="cDG mtM mbXS semibold small">
							Tag{" "}
							<span className="cDG normal small">
								(e.g. 'Landing Page', or 'Checkout')
							</span>
						</p>
						{issueTags.length > 0 ? (
							<input
								disabled
								type="text"
								className="w100p"
								onFocus={handleTagInputFocus}
								onBlur={handleTagInputBlur}
								value={newIssueTag}
								onChange={(e) =>
									setNewIssueTag(e.currentTarget.value)
								}
							/>
						) : (
							<input
								type="text"
								className="w100p"
								onFocus={handleTagInputFocus}
								onBlur={handleTagInputBlur}
								value={newIssueTag}
								onChange={(e) =>
									setNewIssueTag(e.currentTarget.value)
								}
							/>
						)}

						<div className="w100p posRel">
							{issueTags.map((tag) => (
								<div
									className="label mrS cPointer inputList"
									style={{
										backgroundColor: "rgb(68, 200, 129)",
									}}
									onClick={(e) => deleteIssueTag(tag)}
								>
									<div className="dFlex aiCenter">
										<div className="h100p mrXS">{tag}</div>

										<Close fill="#fff" size="16px" />
									</div>
								</div>
							))}

							{tagInputFocus ? (
								<div>
									{newIssueTag.length > 0 ||
									tags.length > 0 ? (
										<div className="autopredictContainer posAbs w100p mtXS border br bgW pbXS">
											{newIssueTag.length > 0 ? (
												<div
													className="ptXS plXS prXS"
													onMouseDown={() =>
														addIssueTag(newIssueTag)
													}
												>
													<div className="pS autopredictItem br cPointer">
														<div className="dFlex aiCenter">
															<div
																className="dot mrS"
																style={{
																	backgroundColor:
																		"#5182f8",
																}}
															/>
															<p className="cDG small">
																Add "
																<span className="semibold">
																	{
																		newIssueTag
																	}
																</span>
																"
															</p>
														</div>
													</div>
												</div>
											) : null}
											{tags.map((tag) => (
												<div
													className="ptXS plXS prXS"
													onMouseDown={() =>
														addIssueTag(tag)
													}
												>
													<div className="pS autopredictItem cPointer">
														<div className="dFlex aiCenter">
															<div
																className="dot mrS"
																style={{
																	backgroundColor:
																		"#5182f8",
																}}
															/>
															<p className="cDG small">
																{tag}
															</p>
														</div>
													</div>
												</div>
											))}
										</div>
									) : null}
								</div>
							) : null}
						</div>
						{issue.length > 0 ? (
							<div>
								<button
									className="primary mtM"
									onClick={addIssue}
								>
									Add note
								</button>
							</div>
						) : (
							<div>
								<button
									disabled
									className="primary mtM"
									onClick={addIssue}
								>
									Add note
								</button>
							</div>
						)}
					</div>
					<div className="fg1">
						<div className="test mbS">
							<div className="pS">
								<div
									className="pS br cPointer dFlex aiCenter"
									style={{
										transition: "all 0.2s ease",
										background: overviewHover
											? "#f6f9fc"
											: "transparent",
									}}
									onClick={() =>
										setOverviewExpanded(!overviewExpanded)
									}
									onMouseEnter={() => setOverviewHover(true)}
									onMouseLeave={() => setOverviewHover(false)}
								>
									<Dropdown
										fill="#1c1c1c"
										size="7px"
										open={overviewExpanded}
									/>
									<p className="cDG semibold small mlS">
										Overview
									</p>
								</div>
							</div>
							{overviewExpanded ? (
								<div className="plM prM pbM">
									<div className="ptM pbM dFlex aiFlexStart">
										{issues.length > 0 ? (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{issues.length}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Notes</p>
											</div>
										) : (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Notes</p>
											</div>
										)}
										{totalUpvotes > 0 ? (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{totalUpvotes}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Upvotes</p>
											</div>
										) : (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Upvotes</p>
											</div>
										)}
										{totalCategories.length > 0 ? (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{totalCategories.length}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Categories</p>
											</div>
										) : (
											<div className="br borderR plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Categories</p>
											</div>
										)}
										{totalTags.length > 0 ? (
											<div className="br plM prM taC w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS">
														{totalTags.length}
													</h3>
													<div
														className="dIB statArrowBoundary ptXS"
														style={{
															backgroundColor:
																"var(--greenAlpha)",
														}}
													>
														<ArrowUp
															size="17px"
															fill="var(--green)"
														/>
													</div>
												</div>
												<p>Tags</p>
											</div>
										) : (
											<div className="br plM prM taC mrS w25p">
												<div className="flexContainer jcCenter mbS">
													<h3 className="semibold cDG dIB mrS o2">
														0
													</h3>
												</div>
												<p className="o4">Tags</p>
											</div>
										)}
									</div>
								</div>
							) : null}
						</div>
						<div className="test mbS">
							{chartsExpanded ? (
								<div className="pS dFlex aiCenter">
									<div
										className="pS br cPointer dFlex aiCenter fg1"
										style={{
											transition: "all 0.2s ease",
											background: chartsHover
												? "#f6f9fc"
												: "transparent",
										}}
										onClick={() =>
											setChartsExpanded(!chartsExpanded)
										}
										onMouseEnter={() =>
											setChartsHover(true)
										}
										onMouseLeave={() =>
											setChartsHover(false)
										}
									>
										<Dropdown
											fill="#1c1c1c"
											size="7px"
											open={chartsExpanded}
										/>
										<p className="cDG semibold small mlS">
											Charts
										</p>
									</div>
									<div
										className="pS br cPointer hoverOpacity mlS"
										onClick={() => setActiveChart("Bar")}
										style={{
											background:
												activeChart === "Bar"
													? "#f6f9fc"
													: "transparent",
										}}
									>
										<p className="cDG normal small">Bar</p>
									</div>
									<div
										className="pS br cPointer hoverOpacity mlS"
										onClick={() => setActiveChart("Radar")}
										style={{
											background:
												activeChart === "Radar"
													? "#f6f9fc"
													: "transparent",
										}}
									>
										<p className="cDG normal small">
											Radar
										</p>
									</div>
								</div>
							) : (
								<div className="pS dFlex aiCenter">
									<div
										className="pS br cPointer dFlex aiCenter fg1"
										style={{
											transition: "all 0.2s ease",
											background: chartsHover
												? "#f6f9fc"
												: "transparent",
										}}
										onClick={() =>
											setChartsExpanded(!chartsExpanded)
										}
										onMouseEnter={() =>
											setChartsHover(true)
										}
										onMouseLeave={() =>
											setChartsHover(false)
										}
									>
										<Dropdown
											fill="#1c1c1c"
											size="7px"
											open={chartsExpanded}
										/>
										<p className="cDG semibold small mlS">
											Charts
										</p>
									</div>
								</div>
							)}
							{chartsExpanded ? (
								<div className="plM prM pbM">
									{issuesWithCategories.length > 0 ||
									issuesWithTags.length > 0 ? (
										<DataVisualisation
											issues={issues}
											activeChart={activeChart}
										/>
									) : (
										<div className="w100p">
											<div className="border br pM taC c w100p">
												<div className="o2">
													<h3 className="semibold mtL mbL">
														No data to show.
													</h3>
												</div>
											</div>
										</div>
									)}
								</div>
							) : null}
						</div>
						<div className="test mbS">
							<div className="pS">
								<div
									className="pS br cPointer dFlex aiCenter"
									style={{
										transition: "all 0.2s ease",
										background: notesHover
											? "#f6f9fc"
											: "transparent",
									}}
									onClick={() =>
										setNotesExpanded(!notesExpanded)
									}
									onMouseEnter={() => setNotesHover(true)}
									onMouseLeave={() => setNotesHover(false)}
								>
									<Dropdown
										fill="#1c1c1c"
										size="7px"
										open={notesExpanded}
									/>
									<p className="cDG semibold small mlS">
										Notes
									</p>
								</div>
							</div>

							{notesExpanded ? (
								<div className="plM prM pbM">
									{(issues.length > 0) |
									(issue.length > 0) ? (
										<div>
											{issue.length > 0 ? (
												<div className="borderDash br pS mbS bgW">
													<div className="o4 dFlex aiCenter">
														<div className="fg1 pS">
															<p className="cDG w100p">
																{issue}
															</p>
															<div className="mtS">
																{issueCategories &&
																issueCategories.length >
																	0 ? (
																	<div className="dIB">
																		{issueCategories.map(
																			(
																				category
																			) => (
																				<div className="mbXS dIB">
																					<Label
																						value={
																							category
																						}
																						allIssues={
																							issues
																						}
																						content="categories"
																					/>
																				</div>
																			)
																		)}
																	</div>
																) : null}
																{issueTags &&
																issueTags.length >
																	0 ? (
																	<div className="dIB">
																		{issueTags.map(
																			(
																				tag
																			) => (
																				<div className="mbXS dIB">
																					<Label
																						value={
																							tag
																						}
																						allIssues={
																							issues
																						}
																						content="tags"
																					/>
																				</div>
																			)
																		)}
																	</div>
																) : null}
															</div>
														</div>
														<div className="br border pM taC">
															<Upvote
																fill="#1c1c1c"
																size="10px"
															/>
															<p className="small bold">
																0
															</p>
														</div>
													</div>
												</div>
											) : null}

											{issues.map((issue) => (
												<CardIssue
													key={issue.id}
													issueId={issue.id}
													allIssues={issues}
													issue={issue.issue}
													categories={
														issue.categories
													}
													tags={issue.tags}
													upvotes={issue.upvotes}
													delete={deleteIssue}
													upvote={upvoteIssue}
												/>
											))}
										</div>
									) : (
										<div className="w100p">
											<div className="border br pM taC c w100p">
												<div className="o2">
													<h3 className="semibold mtL mbL">
														No data to show.
													</h3>
												</div>
											</div>
										</div>
									)}
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const condition = (authUser) => !!authUser;

export default withAuthorisation(condition)(Playground);
