// General
import React, { Component } from "react";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../store/actions";

// Cookies
import Cookies from "js-cookie";

// Router
import { Link, withRouter } from "react-router-dom";

// Firebase
import firebase from "../../components/Firebase";
import { withFirebase } from "../../components/Firebase";

// Recompose
import { compose } from "recompose";

// Constants
import * as ROUTES from "../../constants/routes";

// Icons
import CircleLogo from "../../assets/icons/CircleLogo/CircleLogo";

const SignUp = () => (
	<div>
		<div className="bgG pbXXXL">
			<div className="container">
				<div className="ptXL fiB">
					<div className="taC c mw650 pbM">
						<CircleLogo size="70px" />
						<h3 className="mbS mtM bold">Create an account</h3>
						<p className="mbS">
							Already have an account &mdash;{" "}
							<Link to="/sign-in">
								<span className="semibold">sign in</span>
							</Link>
						</p>
					</div>
				</div>
				<SignUpForm />
			</div>
		</div>
	</div>
);

const INITIAL_STATE = {
	username: "",
	email: "",
	passwordOne: "",
	passwordTwo: "",
	error: null,
};

class SignUpFormBase extends Component {
	constructor(props) {
		super(props);

		this.state = { ...INITIAL_STATE };
	}

	onSubmit = (event) => {
		const { username, email, passwordOne } = this.state;

		this.props.firebase

			.doCreateUserWithEmailAndPassword(email, passwordOne)

			.then((authUser) => {
				const activeUserId = authUser.user.uid;
				Cookies.set("activeUserId", activeUserId);
				const inviteId = this.props.match.params.id;

				if (inviteId) {
					firebase
						.firestore()
						.collection("users")
						.doc(activeUserId)
						.set({
							username: username,
							email: email,
							accountId: inviteId,
						});
					Cookies.set("accountId", inviteId);
				} else {
					firebase
						.firestore()
						.collection("users")
						.doc(activeUserId)
						.set({
							username: username,
							email: email,
							accountId: activeUserId,
						});
					Cookies.set("accountId", activeUserId);
				}
			})

			.then((authUser) => {
				this.setState({ ...INITIAL_STATE });
				this.props.history.push(ROUTES.WELCOME);
			})

			.catch((error) => {
				this.setState({ error });
			});

		event.preventDefault();
	};

	onChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const { username, email, passwordOne, passwordTwo, error } = this.state;
		const isInvalid =
			passwordOne !== passwordTwo ||
			passwordOne === "" ||
			email === "" ||
			username === "";
		return (
			<div className="test mw470 c br ptM pbL plL prL fiB">
				<form onSubmit={this.onSubmit}>
					<p className="mtS mbXS cDG semibold">Full name</p>
					<input
						className="w100p mbS"
						name="username"
						value={username}
						onChange={this.onChange}
						type="text"
					/>
					<p className="mtS mbXS cDG semibold">Email address</p>
					<input
						className="w100p"
						name="email"
						value={email}
						onChange={this.onChange}
						type="text"
					/>
					<p className="mtS mbXS cDG semibold">Create a password</p>
					<input
						className="w100p mbS"
						name="passwordOne"
						value={passwordOne}
						onChange={this.onChange}
						type="password"
					/>
					<p className="mtS mbXS cDG semibold">Confirm password</p>
					<input
						className="w100p mbM"
						name="passwordTwo"
						value={passwordTwo}
						onChange={this.onChange}
						type="password"
					/>
					<button
						className="w100p primary"
						type="submit"
						disabled={isInvalid}
					>
						Create account
					</button>

					{error && (
						<p className="error small semibold mtM">
							{error.message}
						</p>
					)}
				</form>
			</div>
		);
	}
}

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUp;

export { SignUpForm };
