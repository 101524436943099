// General
import React from "react";
import ReactDOM from "react-dom";

// Redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./store/reducer/reducer";

// CSS
import "./reset.css";
import "./index.css";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";

// Firebase
import { FirebaseHelpers, FirebaseContext } from "./components/Firebase";

const store = createStore(reducer);

ReactDOM.render(
	<FirebaseContext.Provider value={new FirebaseHelpers()}>
		<Provider store={store}>
			<App />
		</Provider>
	</FirebaseContext.Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
