// General
import React, { Component } from "react";

// Firebase
import { withFirebase } from "../../components/Firebase";

// Icons
import CircleLogo from "../../assets/icons/CircleLogo/CircleLogo";

const forgotPassword = () => (
  <div>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <div>
        <div className="bgG pbXXXL">
          <div className="container">
            <div className="ptXL fiB">
              <div className="taC c mw650 pbM">
                <CircleLogo size="70px" />
                <h3 className="mbS mtM bold">Forgot your password</h3>
              </div>
            </div>
            <div className="test mw470 c br ptM pbL plL prL fiB">
              <form onSubmit={this.onSubmit}>
                <p className="mtS mbXS cDG semibold">Email address</p>
                <input
                  className="w100p mbM"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  type="text"
                />

                <button
                  className="w100p primary"
                  disabled={isInvalid}
                  type="submit"
                >
                  Reset My Password
                </button>

                {error && (
                  <p className="error small semibold mtM">{error.message}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default forgotPassword;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm };
