// General
import React, { useState, useEffect } from "react";

// Chart
import { Radar } from "react-chartjs-2";
import { HorizontalBar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";

// Router
import { Link } from "react-router-dom";

// Components
import Footer from "../../components/UI/Footer/Footer";

const DataVisualisation = (props) => {
  const issues = props.issues;
  const issuesWithCategories = issues.filter(
    (issue) => issue.categories.length > 0
  );
  const issuesWithTags = issues.filter((issue) => issue.tags.length > 0);
  const [categoryData, setCategoryData] = useState({});
  const allIssueCategories = issues.map((issue) => issue.categories);
  const categories = allIssueCategories.filter(
    (category) => category.length > 0
  );
  const [tagData, setTagData] = useState({});
  const allIssueTags = issues.map((issue) => issue.tags);
  const tags = allIssueTags.filter((tag) => tag.length > 0);

  const categoryChart = () => {
    const categoryCounter = {};
    categories.forEach(function(x) {
      categoryCounter[x] = (categoryCounter[x] || 0) + 1;
    });
    const categoryNames = Object.getOwnPropertyNames(categoryCounter);

    const categoryNamesCounter = categoryNames.map((data) =>
      Object.getOwnPropertyDescriptor(categoryCounter, data)
    );

    const categoryFrequency = categoryNamesCounter.map((data) => data.value);

    const tagCounter = {};
    tags.forEach(function(x) {
      tagCounter[x] = (tagCounter[x] || 0) + 1;
    });
    const tagNames = Object.getOwnPropertyNames(tagCounter);

    const tagNamesCounter = tagNames.map((data) =>
      Object.getOwnPropertyDescriptor(tagCounter, data)
    );

    const tagFrequency = tagNamesCounter.map((data) => data.value);

    const allIssuesAndTagNames = categoryNames.concat(tagNames);
    const allIssuesAndTagFrequencies = categoryFrequency.concat(tagFrequency);

    setCategoryData({
      labels: allIssuesAndTagNames,
      datasets: [
        {
          data: allIssuesAndTagFrequencies,
          borderWidth: 1,
          lineTension: 0.2,
          borderCapStyle: ["round"],
          borderJoinStyle: ["round"],
          backgroundColor: "rgba(255, 179, 0, 0.2)",
          borderColor: "#FFB300",
        },
      ],
    });
  };

  useEffect(() => {
    if (issues) {
      categoryChart();
    }
  }, [issues]);

  return (
    <div
      className="dFlex w100p br bgG"
      key={issuesWithCategories.length + issuesWithTags.length}
    >
      {issuesWithCategories.length > 0 ? (
        <div className="prS mbM w100p">
          <div className="pM">
            {props.activeChart === "Bar" ? (
              <HorizontalBar
                data={categoryData}
                options={{
                  tooltips: { enabled: false },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          min: 0,
                          stepSize: 1,
                        },
                      },
                    ],
                  },
                  legend: {
                    display: false,
                  },
                }}
              />
            ) : null}
            {props.activeChart === "Radar" ? (
              <Radar
                data={categoryData}
                options={{
                  tooltips: { enabled: false },
                  legend: {
                    display: false,
                  },

                  responsive: true,
                  scale: {
                    gridLines: {
                      circular: true,
                    },
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      precision: 0,
                    },
                  },
                }}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DataVisualisation;
