// General
import React, { useState } from "react";

// Firebase
import firebase from "../../components/Firebase";

// Router
import { Link } from "react-router-dom";

const CardProject = (props) => {
	const [actions, setActions] = useState(false);
	const numberOfIsses = props.issues.filter(
		(issue) => issue.projectId === props.id
	);

	function toggleActions() {
		const currentValue = actions;
		const updatedValue = !currentValue;
		setActions(updatedValue);
	}

	return (
		<div className="projects-card" key={props.key}>
			<div className="pM borderB">
				<div className="dFlex aiCenter mbS">
					<div className="dFlex aiCenter fg1">
						<Link
							to={"/projects/" + props.id}
							onClick={props.select}
						>
							<div
								className="projectImageSmall mrS cPointer"
								style={{
									backgroundImage: `url(${props.imageURL})`,
									backgroundSize: "cover",
									backgroundPosition: "center",
								}}
							/>
						</Link>
						<Link
							to={"/projects/" + props.id}
							onClick={props.select}
						>
							{props.name === undefined ||
							props.name.length === 0 ? (
								<h3 className="cardProjectTitle o4">
									Untitled
								</h3>
							) : (
								<h3 className="cardProjectTitle">
									{props.name}
								</h3>
							)}
						</Link>
					</div>

					<button
						className="tertiary projects-card-cta"
						onClick={toggleActions}
					>
						Actions
					</button>

					{actions ? (
						<div className="posRel">
							<div className="fiB popOver actions pbXS">
								<Link to={"/projects/" + props.id}>
									<div className="plXS prXS ptXS">
										<p className="cDG pbS navLink">View</p>
									</div>
								</Link>
								<div
									className="plXS prXS ptXS"
									onClick={() =>
										props.delete(props.id, props.name)
									}
								>
									<p className="cDG pbS navLink">Delete</p>
								</div>
							</div>
						</div>
					) : null}
				</div>
				<div className="dFlex">
					{numberOfIsses.length === 1 ? (
						<p className="mrS semibold">
							{numberOfIsses.length} note
						</p>
					) : (
						<p className="mrS semibold">
							{numberOfIsses.length} notes
						</p>
					)}
				</div>
			</div>

			<div className="pM">
				<p className="small">Last edited {props.edited}</p>
			</div>
		</div>
	);
};

export default CardProject;
