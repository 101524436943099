import React from "react";

const circleLogo = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={props.size}
			height={props.size}
			viewBox="0 0 340 340"
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g>
					<circle cx="170" cy="170" r="170" fill="#1C1C1C"></circle>
					<path
						fill="#FFF"
						d="M176.448 93.04a4 4 0 011.696 3.27v55.38h50.287a4 4 0 013.27 6.305l-62.288 88.38a4 4 0 01-7.27-2.304v-55.38h-50.287a4 4 0 01-3.27-6.305l62.288-88.38a4 4 0 015.574-.966z"
					></path>
				</g>
			</g>
		</svg>
	);
};

export default circleLogo;
