// React
import React from "react";

// Assets: Images
import landing2 from "../../../assets/images/landing/landing-2.png";
import landing3 from "../../../assets/images/landing/landing-3.png";

const LandingExplainer = (props) => {
	return (
		<div className="container">
			<div className="landing-explainer">
				<h2 className="landing-explainer-header">How it works</h2>
				<p className="landing-explainer-description">
					Designed to save product &amp; research teams time. Generate
					shareable reports instantly from the notes your team takes
					during user feedback sessions.
				</p>
				<div className="landing-explainer-grid">
					<div className="landing-explainer-column">
						<div className="landing-explainer-module">
							<div className="landing-explainer-module-content">
								<p className="landing-explainer-module-strapline">
									01
								</p>
								<h3 className="landing-explainer-module-header">
									<span className="landing-explainer-module-header-strong">
										Add notes.
									</span>{" "}
									Collaboratively add, tag, and prioritise
									notes as you observe user feedback sessions.
								</h3>
							</div>
							<img
								src={landing2}
								className="landing-image"
								style={{
									bottom: "0",
								}}
							/>
						</div>
					</div>
					<div className="landing-explainer-column">
						<div className="landing-explainer-module">
							<div className="landing-explainer-module-content">
								<p className="landing-explainer-module-strapline">
									02
								</p>
								<h3 className="landing-explainer-module-header">
									<span className="landing-explainer-module-header-strong">
										Generate reports.
									</span>{" "}
									Turn notes into an actionable report in one
									click. Share via link or download to a PDF.
								</h3>
							</div>
							<img
								src={landing3}
								className="landing-image"
								style={{
									bottom: "0",
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingExplainer;
