// General
import React from "react";

// Constants
import * as ROUTES from "../../../constants/routes";

// Router
import { NavLink } from "react-router-dom";

// Icons
import Logo from "../../../assets/icons/Logo/Logo";

const footer = () => (
	<footer>
		<div className="footer-container">
			<div className="footer-logo">
				<NavLink to="/" exact>
					<Logo />
				</NavLink>
			</div>
			<nav>
				<ul>
					{/*
					<li className="footer-list-item">
						<a href="mailto:support@typeflow.app">
							<div className="footer-link">
								support@typeflow.app
							</div>
						</a>
					</li>
					<li className="footer-list-item">
						<NavLink
							to={ROUTES.PRIVACY}
							exact
							className="footer-link"
						>
							Privacy Policy
						</NavLink>
					</li>*/}
					<li className="footer-list-item">
						<p>Copyright © 2020 Typeflow</p>
					</li>
				</ul>
			</nav>
		</div>
	</footer>
);

export default footer;
