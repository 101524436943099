// General
import React, { useState, useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Cookies
import Cookies from "js-cookie";

// Router
import { Link } from "react-router-dom";

// Firebase
import firebase from "../../components/Firebase";

// Authorisation
import { AuthUserContext, withAuthorisation } from "../../components/Session";

// Router
import { useHistory } from "react-router-dom";

// Components
import CardProject from "../../components/CardProject/CardProject";

const SORT_OPTIONS = {
	LAST_EDITED: { column: "edited", direction: "desc" },
};

function useAuthUser(activeUserId) {
	const [authUser, setAuthUser] = useState({});

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("users")
			.doc(activeUserId)
			.get()
			.then((doc) => {
				const data = doc.data();
				setAuthUser(data);
			});
	}, []);

	return authUser;
}

function useProjects(sortBy = "LAST_EDITED", activeAccountId) {
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("projects")
			.where("accountId", "==", activeAccountId)
			.orderBy(
				SORT_OPTIONS[sortBy].column,
				SORT_OPTIONS[sortBy].direction
			)
			.onSnapshot((snapshot) => {
				const newProjects = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setProjects(newProjects);
			});
		return () => unsubscribe();
	}, []);

	return projects;
}

function useIssues(activeAccountId) {
	const [issues, setIssues] = useState([]);

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("issues")
			.where("accountId", "==", activeAccountId)
			.onSnapshot((snapshot) => {
				const newIssues = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setIssues(newIssues);
			});
		return () => unsubscribe();
	}, []);

	return issues;
}

function useAssignedUserIds(activeAccountId) {
	const [assignedUserIds, setAssignedUserIds] = useState([]);

	useEffect(() => {
		const unsubscribe = firebase
			.firestore()
			.collection("users")
			.where("accountId", "==", activeAccountId)
			.onSnapshot((snapshot) => {
				const newAssignedUserIds = snapshot.docs.map((doc) => doc.id);
				setAssignedUserIds(newAssignedUserIds);
			});
		return () => unsubscribe();
	}, []);

	return assignedUserIds;
}

const Projects = () => {
	const activeUserId = Cookies.get("activeUserId");
	const activeAccountId = Cookies.get("accountId");
	const authUser = useAuthUser(activeUserId);
	const [sortBy, setSortBy] = useState("LAST_EDITED");
	const projects = useProjects(sortBy, activeAccountId);
	const history = useHistory();
	const issues = useIssues(activeAccountId);
	const assignedUserIds = useAssignedUserIds(activeAccountId);
	var dayjs = require("dayjs");
	var relativeTime = require("dayjs/plugin/relativeTime");
	dayjs.extend(relativeTime);

	// New project
	const [newProjectName, setNewProjectName] = useState("");
	const [newProjectImageURL, setNewProjectImageURL] = useState(null);

	// Project Delete
	const [projectIdToDelete, setProjectIdToDelete] = useState("");
	const [projectNameToDelete, setProjectNameToDelete] = useState("");
	const [checkProjectNameToDelete, setCheckProjectNameToDelete] = useState(
		""
	);

	// UI
	const [deleteModal, setDeleteModal] = useState(false);
	const [newProjectModal, setNewProjectModal] = useState(false);

	function newProject(e) {
		const now = dayjs().toString();

		firebase
			.firestore()
			.collection("projects")
			.add({
				created: now,
				edited: now,
				assignedUserIds: assignedUserIds,
				accountId: activeAccountId,
				name: newProjectName,
				imageURL: newProjectImageURL,
				nameIndexable: "",
				categories: [
					"Accessibility",
					"Bug",
					"Content",
					"Copy",
					"Design",
					"Feedback",
					"UI",
					"UX",
				],
			})
			.then(function(docRef) {
				history.push("/projects/" + docRef.id);
			})
			.catch(function(error) {
				alert("Error adding document: ", error);
			});
	}

	function selectProjectToDelete(projectId, projectName) {
		setProjectIdToDelete(projectId);
		setProjectNameToDelete(projectName);
		setDeleteModal(true);
		setCheckProjectNameToDelete("");
	}

	function deleteProject(projectId) {
		firebase
			.firestore()
			.collection("projects")
			.doc(projectId)
			.delete();

		const issuesQuery = firebase
			.firestore()
			.collection("issues")
			.where("projectId", "==", projectId);

		issuesQuery.get().then(function(querySnapshot) {
			querySnapshot.forEach(function(doc) {
				doc.ref.delete();
			});
		});
		setDeleteModal(false);
	}

	return (
		<React.Fragment>
			{deleteModal ? (
				<div className="backdrop">
					<div className="modal pM br">
						<p className="large cDG semibold mbXS">
							Delete project
						</p>
						<p className="normal mbM">
							Deleting projects is permanent and cannot be undone.
							Enter the project name{" "}
							<span className="bold">{projectNameToDelete}</span>{" "}
							to continue.
						</p>
						<input
							type="text"
							className="w100p mbM"
							value={checkProjectNameToDelete}
							onChange={(e) =>
								setCheckProjectNameToDelete(
									e.currentTarget.value
								)
							}
						/>
						<div className="dFlex">
							<div className="fg1" />
							<button
								className="secondary"
								onClick={() => setDeleteModal(false)}
							>
								Cancel
							</button>
							{checkProjectNameToDelete ===
							projectNameToDelete ? (
								<button
									className="mlS destructive"
									onClick={() =>
										deleteProject(projectIdToDelete)
									}
								>
									Delete project
								</button>
							) : (
								<button disabled className="mlS destructive">
									Delete project
								</button>
							)}
						</div>
					</div>
				</div>
			) : null}
			{newProjectModal ? (
				<div className="backdrop">
					<div className="modal pM br">
						<p className="large cDG semibold mbM">
							Create a new project
						</p>
						<p className="cDG mtM mbXS semibold small">
							Project name
						</p>
						<input
							type="text"
							className="w100p mbM"
							value={newProjectName}
							onChange={(e) =>
								setNewProjectName(e.currentTarget.value)
							}
						/>
						<p className="cDG mbXS semibold small">
							Project image URL{" "}
							<span className="normal">(Optional)</span>
						</p>
						<input
							type="text"
							className="w100p mbM"
							value={newProjectImageURL}
							onChange={(e) =>
								setNewProjectImageURL(e.currentTarget.value)
							}
						/>
						<div className="dFlex">
							<div className="fg1" />
							<button
								className="secondary"
								onClick={() => setNewProjectModal(false)}
							>
								Cancel
							</button>
							{newProjectName.length > 0 ? (
								<button
									className="mlS primary"
									onClick={newProject}
								>
									Create new project
								</button>
							) : (
								<button disabled className="mlS primary">
									Create new project
								</button>
							)}
						</div>
					</div>
				</div>
			) : null}
			<div className="projects-header">
				<div className="projects-header-content">
					<div className="projects-header-content-copy">
						<h2 className="semibold">Projects</h2>
						<p className="mtS">View, edit, and create projects</p>
					</div>
					<Link to="/settings">
						<button className="secondary projects-header-cta">
							Invite team members
						</button>
					</Link>
					<button
						className="primary"
						onClick={() => setNewProjectModal(true)}
					>
						New project
					</button>
				</div>
			</div>

			<div className="container">
				<div className="projects-container fiB">
					{projects && projects.length > 0 ? (
						<div className="projects-grid">
							{projects.map((project) => (
								<CardProject
									key={project.id}
									imageURL={project.imageURL}
									id={project.id}
									name={project.name}
									description={project.description}
									issues={issues}
									edited={dayjs(project.edited).fromNow()}
									delete={selectProjectToDelete}
								/>
							))}
						</div>
					) : (
						<div className="projects-empty">
							<h3 className="projects-empty-heading">
								You do not have any projects.
							</h3>
							<p className="projects-empty-description">
								Create a new project to get started.
							</p>
							<button
								className="primary projects-empty-cta"
								onClick={() => setNewProjectModal(true)}
							>
								New project
							</button>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

const condition = (authUser) => !!authUser;

export default withAuthorisation(condition)(Projects);
