import React from "react";

const dropdown = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={props.size}
			height={props.size}
			viewBox="0 0 16 16"
			style={{
				transition: "all 0.2s ease",
				transform: props.open === true ? "rotate(0)" : "rotate(-90deg)",
			}}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<path
					fill={props.fill}
					d="M9.789 3.578l5.764 11.528A2 2 0 0113.763 18H2.237a2 2 0 01-1.789-2.894L6.211 3.578a2 2 0 013.578 0z"
					transform="matrix(1 0 0 -1 0 18)"
				></path>
			</g>
		</svg>
	);
};

export default dropdown;
