// React
import React, { useState } from "react";

// Components
import LandingHero from "../../components/Landing/LandingHero/LandingHero";
import LandingExplainer from "../../components/Landing/LandingExplainer/LandingExplainer";
import LandingPricing from "../../components/Landing/LandingPricing/LandingPricing";
import Footer from "../../components/UI/Footer/Footer";

// Router
import { Link } from "react-router-dom";

// Assets: Images
import landing2 from "../../assets/images/landing/landing-2.png";
import landing3 from "../../assets/images/landing/landing-3.png";

// Assets: Icons
import OpenInNew from "../../assets/icons/OpenInNew/OpenInNew";
import ArrowForward from "../../assets/icons/ArrowForward/ArrowForward";

const Landing = () => {
	const [billing, setBilling] = useState("Yearly");
	const [emailValue, setEmailValue] = useState("");

	return (
		<div className="window">
			<LandingHero />
			<LandingExplainer />
			<div className="mbXXXL" />
			<div className="line c" />
			<div className="w100p">
				<div className="container h100p">
					<div className="ptXXXL">
						<h2 className="bold taC c pbM">
							Currently available <br />
							for early access
						</h2>
						<p className="taC c mtS large">
							Sign up below to receive your BETA invite link.
						</p>
					</div>
					<form
						action="https://samstuartcook.us19.list-manage.com/subscribe/post"
						method="POST"
						className="landing-signup mtL mbXXXL"
					>
						<input
							type="hidden"
							name="u"
							value="8b2ac6cbcc833b68b31df6e9d"
						/>
						<input type="hidden" name="id" value="d4342bc21d" />

						<input
							type="email"
							placeholder="Email address"
							className="landing-signup-input"
							name="EMAIL"
							value={emailValue}
							onChange={(e) => {
								setEmailValue(e.target.value);
							}}
							autoCapitalize="off"
							autoCorrect="off"
						/>

						<button
							type="submit"
							value="Subscribe"
							name="subscribe"
							id="mc-embedded-subscribe"
							className="landing-signup-cta primary"
						>
							Get early access
						</button>
					</form>
				</div>
			</div>

			{/*<div className="w100p">
				<div className="container h100p">
					<div className="ptXXXL">
						<h2 className="bold taC c pbM">
							Ready to get started?
							<br />
							Create an account today.
						</h2>
						<p className="taC c mtS">
							Save 50% by with a yearly subscription
						</p>
						{billing === "Yearly" ? (
							<p
								className="taC c mtS bold cPointer"
								onClick={() => setBilling("Monthly")}
							>
								Switch to monthly billing
							</p>
						) : (
							<p
								className="taC c mtS bold cPointer"
								onClick={() => setBilling("Yearly")}
							>
								Switch to yearly billing
							</p>
						)}
					</div>
					<LandingPricing billing={billing} />
				</div>
			</div>*/}
			<Footer />
			<div className="backgroundWhite" />
		</div>
	);
};
export default Landing;
