import React from "react";

const arrowBack = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height={props.size}
			viewBox="0 0 24 24"
			width={props.size}
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path
				d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
				fill={props.fill}
			/>
		</svg>
	);
};

export default arrowBack;
